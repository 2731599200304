import React from "react"
import { connect } from "react-redux"
import { Creators as filterActions } from "../redux/postsFilterReducer"

const FiterButton = ({ onClick, active, title }) => {
  const cssClass = active ? "filter-button active" : "filter-button"
  return (
    <h5 onClick={onClick} className={cssClass}>
      {title}
    </h5>
  )
}

const HomeFilters = ({ setCurrentCategory, currentCategory }) => {
  return (
    <div className="home-filters">
      <FiterButton
        onClick={() => setCurrentCategory(null)}
        title={"Todos"}
        active={currentCategory === null}
      />

      <FiterButton
        onClick={() => setCurrentCategory("coding")}
        title={"Código"}
        active={currentCategory === "coding"}
      />

      <FiterButton
        onClick={() => setCurrentCategory("dancing")}
        title={"Dança"}
        active={currentCategory === "dancing"}
      />
    </div>
  )
}
const mapStateToProps = state => {
  return { currentCategory: state.postsFilter.currentCategory }
}
export default connect(mapStateToProps, { ...filterActions })(HomeFilters)
