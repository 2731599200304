import React, { useState } from "react"
import { Link } from "gatsby"
import HomeFilters from "./homeFilters"
import { UIColorToggler } from "./toggle"
import Bio from "./bio"
import { configLocales } from "../utils/moment-utils"
configLocales()

const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath
  let header

  if (isRootPath) {
    header = (
      <>
        <h1
          style={{
            margin: 0,
            marginBottom: 7,
          }}
        >
          <Link to="/">{title}</Link>
        </h1>
        <UIColorToggler />
      </>
    )
  } else {
    header = (
      <>
        <Link className="header-link-home" to="/">
          voltar ao início
        </Link>
        <UIColorToggler />
      </>
    )
  }

  return (
    <div className="global-wrapper" data-is-root-path={isRootPath}>
      <header className="global-header">{header}</header>
      {isRootPath ? <HomeFilters /> : null}
      <main>{children}</main>
      <footer>{isRootPath ? <Bio /> : null}</footer>
    </div>
  )
}

export default Layout
